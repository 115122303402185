<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5 ">
              <div class="">
                <div class="flex align-items-center ">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-building" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">Institution Details</div>
                    <div class="text-sm text-500"> Details of institution record </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section ">
      <div class="container">
        <div class="grid ">
          <div class="col comp-grid">
            <div class="">
              <div>
                <div class="relative-position" style="min-height:100px">
                  <template v-if="!loading && item">
                    <div class="row q-col-gutter-x-md">
                      <div class="col-12">
                        <div class="grid align-items-center">
                          <div class="col">
                            Institution Name:
                          </div>
                          <div class="col font-bold">{{ item.institution_name }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Telephone:
                          </div>
                          <div class="col font-bold">{{ item.telephone }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Location:
                          </div>
                          <div class="col font-bold">{{ item.location }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Digital Address:
                          </div>
                          <div class="col font-bold">{{ item.digital_address }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Institutional Email:
                          </div>
                          <div class="col font-bold">{{ item.institutional_email }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Logo:
                          </div>
                          <div class="col font-bold">
                            <image-viewer image-size="large" image-preview-size="" :src="item.logo" width="50px"
                              height="50px" :num-display="1">
                            </image-viewer>
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Website Url:
                          </div>
                          <div class="col font-bold">{{ item.website_url }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Affiliated Institution:
                          </div>
                          <div class="col font-bold">{{ item.affiliated_institution }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Year Of Establishment:
                          </div>
                          <div class="col font-bold">{{ item.year_of_establishment }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Start Of Accreditation:
                          </div>
                          <div class="col font-bold">
                            {{ $utils.humanDate(item.start_of_accreditation) }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            End Of Accreditation:
                          </div>
                          <div class="col font-bold">
                            {{ $utils.humanDate(item.end_of_accreditation) }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Registrar Name:
                          </div>
                          <div class="col font-bold">{{ item.registrar_name }}</div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            Registrar Phone Number:
                          </div>
                          <div class="col font-bold">{{ item.registrar_phone_number }}</div>
                        </div>
                        <hr />
                        <div class="flex justify-content-start">
                          <Menubar class="p-0" ref="actionMenu" :model="getActionMenuModel(item)" />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="loading">
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width:50px;height:50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
export default {
  name: 'viewmoreCapsinstitutionslistPage',
  components: {
  },
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: 'caps_institutions_list',
    },
    idName: {
      type: String,
      default: 'caps_institution_id',
    },
    routeName: {
      type: String,
      default: 'caps_institutions_listviewmore',
    },
    pagePath: {
      type: String,
      default: 'caps_institutions_list/viewmore',
    },
    apiPath: {
      type: String,
      default: 'caps_institutions_list/viewmore',
    },
  },
  data() {
    return {
      item: {
        default: {
        },
      },
    }
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Caps Institutions List Details"
      }
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_institutions_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("caps_institutions_list/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle
    }
  },
  methods: {
    ...mapActions("caps_institutions_list", ["fetchRecord", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        {
          label: "Delete from List",
          command: (event) => { this.deleteItem(data.caps_institution_id) },
          icon: "pi pi-minus-circle"
        }
      ]
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() {
  },
  async created() {
  },
};
</script>
<style scoped></style>
